import { Heading, Icon, Popover } from '@loveholidays/design-system';
import React from 'react';

import { TooltipFilterModal } from './TooltipFilterModal';
import { ClassNameProps } from '@ComponentProps';
import { SearchFilterProps } from '@Components/LayoutComponents/SearchResultsComponent/filters/SearchFilter';
import { SomeRequired } from '@Core/types';
import { TooltipHastContent } from '@UX/Tooltip/TooltipHastContent';

type TooltipFilterProps = SomeRequired<Pick<SearchFilterProps, 'title' | 'tooltip'>, 'tooltip'> &
  ClassNameProps & {
    isInModal?: boolean;
  };

export const TooltipFilter: React.FC<React.PropsWithChildren<TooltipFilterProps>> = ({
  title,
  tooltip,
  children,
  className,
  isInModal = false,
}) => {
  const icon = (
    <Icon
      name="Markers/Tooltip"
      size="20"
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    />
  );

  return (
    <div className={className}>
      <div
        sx={{
          paddingY: ['3xs', null, 's'],
          paddingX: 's',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Heading
          variant="smallbold"
          as="h3"
        >
          {title}
        </Heading>
        {isInModal ? (
          <TooltipFilterModal content={tooltip}>{icon}</TooltipFilterModal>
        ) : (
          <Popover
            content={
              <TooltipHastContent
                content={tooltip}
                sx={{ padding: 'xs' }}
              />
            }
            trigger="hover"
          >
            {icon}
          </Popover>
        )}
      </div>
      {children}
    </div>
  );
};
